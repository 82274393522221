var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showDialog,
        width: "800px",
        top: "12vh",
        "close-on-click-modal": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-position": "right",
                "label-width": "120px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "签单金额", prop: "total_amount" } },
                [_vm._v(" " + _vm._s(_vm.form.total_amount) + " ")]
              ),
              _vm.type == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "已下单", prop: "already_amount" } },
                    [_vm._v(" " + _vm._s(_vm.form.already_amount) + " ")]
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "累计收款", prop: "receive_amount" } },
                [_vm._v(" " + _vm._s(_vm.form.receive_amount) + " ")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "待收款", prop: "credit_amount" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.type == 1
                          ? _vm.form.credit_amount
                          : _vm.form.arrears_amount
                      ) +
                      " "
                  ),
                ]
              ),
              _c("el-form-item", { attrs: { label: "本次收款", prop: "" } }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "140px", "margin-right": "5px" },
                      attrs: { placeholder: "请输入金额" },
                      model: {
                        value: _vm.m,
                        callback: function ($$v) {
                          _vm.m = $$v
                        },
                        expression: "m",
                      },
                    }),
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "140px" },
                        attrs: { clearable: "", placeholder: "收款方式" },
                        model: {
                          value: _vm.shoukuanF,
                          callback: function ($$v) {
                            _vm.shoukuanF = $$v
                          },
                          expression: "shoukuanF",
                        },
                      },
                      _vm._l(_vm.shoukuanFList, function (i, idx) {
                        return _c("el-option", {
                          key: idx,
                          attrs: { value: i.id, label: i.name },
                        })
                      }),
                      1
                    ),
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "140px", "margin-left": "5px" },
                        attrs: { placeholder: "收款人" },
                        model: {
                          value: _vm.shoukuanR,
                          callback: function ($$v) {
                            _vm.shoukuanR = $$v
                          },
                          expression: "shoukuanR",
                        },
                      },
                      _vm._l(_vm.shoukuanRList, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.user_name, value: item.user_id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "赠送金额", prop: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "140px" },
                    model: {
                      value: _vm.money_gift,
                      callback: function ($$v) {
                        _vm.money_gift = $$v
                      },
                      expression: "money_gift",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("确定")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showDialog = false
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }