<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="800px"
    top="12vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <div class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        label-position="right"
        label-width="120px"
        label-suffix=":"
      >
        <el-form-item label="签单金额" prop="total_amount">
          {{ form.total_amount }}
        </el-form-item>
        <el-form-item v-if="type == 1" label="已下单" prop="already_amount">
          {{ form.already_amount }}
        </el-form-item>
        <el-form-item label="累计收款" prop="receive_amount">
          {{ form.receive_amount }}
        </el-form-item>
        <el-form-item label="待收款" prop="credit_amount">
          {{ type == 1 ? form.credit_amount : form.arrears_amount }}
        </el-form-item>
        <el-form-item label="本次收款" prop="">
          <div style="display: flex">
            <el-input
              v-model="m"
              style="width: 140px; margin-right: 5px"
              placeholder="请输入金额"
            ></el-input>
            <el-select
              v-model="shoukuanF"
              clearable
              style="width: 140px"
              placeholder="收款方式"
            >
              <el-option
                v-for="(i, idx) in shoukuanFList"
                :key="idx"
                :value="i.id"
                :label="i.name"
              ></el-option>
            </el-select>
            <el-select
              v-model="shoukuanR"
              placeholder="收款人"
              style="width: 140px; margin-left: 5px"
            >
              <el-option
                v-for="item in shoukuanRList"
                :key="item.id"
                :label="item.user_name"
                :value="item.user_id"
              ></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="赠送金额" prop="">
          <el-input v-model="money_gift" style="width: 140px"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="dialog-footer">
      <el-button type="primary" @click="handleSubmit">确定</el-button>
      <el-button @click="showDialog = false">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/Employee'
  export default {
    name: 'ShouKuan2',
    components: {},
    data() {
      return {
        type: 1, //1预存款  2预存货
        showDialog: false,
        m: '',
        shoukuanF: '',
        shoukuanFList: [],
        shoukuanR: '',
        shoukuanRList: [],
        form: {},
        money_gift: '',
      }
    },
    computed: {
      title() {
        return this.type == 1 ? '预存款收款' : '预存货收款'
      },
    },
    watch: {
      showDialog(v) {
        if (v) {
          this.m = JSON.parse(
            JSON.stringify(
              this.type == 1
                ? this.form.credit_amount
                : this.form.arrears_amount
            )
          )
        }
      },
    },
    created() {
      postAction('/promoteAdmin/deposit-order/pay-type').then((res) => {
        this.shoukuanFList = res.data
      })
      // 业务员下拉
      postAction('/baseAdmin/common/user-list').then((res) => {
        this.shoukuanRList = res.data
      })
    },
    mounted() {},
    methods: {
      async handleSubmit() {
        /**
         * order_id:预存货id
pay_type:支付方式
money:金额
user_id:收款人id
         */
        let data = {
          order_id: this.form.id,
          pay_type: this.shoukuanF,
          money: this.m,
          user_id: this.shoukuanR,
          money_gift: this.money_gift,
        }
        let res = null
        if (this.type == 1) {
          res = await postAction('/promoteAdmin/deposit-order/make-money', data)
        } else {
          res = await postAction('/promoteAdmin/goods-order/collection', data)
        }
        if (res) {
          this.$message.success('收款成功')
          this.$emit('refresh')
          this.showDialog = false
        }
      },
    },
  }
</script>
<style lang="scss" scoped></style>
